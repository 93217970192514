import React from "react"
import EditIcon from "../../../icons/EditIcon"

const NoInput = props => {
    const {
        attribute,
        value,
        inputType,
        devise,
        classname,
        iconColor,
        backgroundColor,
        edit,
        handleEdit
    } = props;

    const displayValue = () => {
        switch (inputType) {
            case "price":
                return <p className="textInput">
                    {
                        value
                    }
                    {
                        (devise !== undefined && devise !== "")
                        && <span className="devise">{ devise }</span>
                    }
                </p>
            default:
                return <p className="textInput">
                    {
                        value
                    }
                    {
                        (devise !== undefined && devise !== "")
                        && <span className="devise">{ devise }</span>
                    }
                </p>
        }
    }

    return (
        <div
            className={ "noInput " + (classname !== undefined ? classname : "") + (!edit ? " noEdit" : "") }
            style={ (backgroundColor !== undefined ? {backgroundColor: backgroundColor} : {}) }
            onClick={ edit ? () => { handleEdit(attribute) } : () => {} }
        >
            <div className="icons">
                {
                    edit && <EditIcon fill={ iconColor } handleClick={ () => { handleEdit(attribute) } } />
                }
            </div>
            {
                displayValue()
            }
        </div>
    );
}

export default NoInput;
