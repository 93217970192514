import Rest from "../../../class/tool/Rest"

class AuthController {
    #_rest = new Rest()
    _callback = null

    authenticate(email, password) {
        this.#_rest.sendQuery(
            'POST',
            '/login',
            {
                email: email,
                password: password
            },
            this._callback)
    }

    findMyPassword(email) {
        this.#_rest.sendQuery(
            'POST',
            '/findMyPassword',
            {
                email: email
            },
            this._callback)
    }
}

export default AuthController
