import { useEffect } from "react"

const FromProject = () => {
    useEffect(() => {
        localStorage.setItem("target", "client")
        window.location.href = "/"
    }, [])
}

export default FromProject
