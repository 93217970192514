import React from "react"
import FormFindMyPassword from "../../form/login/FormFindMyPassword"

const FindMyPassword = () => {
    return (
        <div className={"container"}>
            <div className={"box"}>
                <div className={"presentation"}/>
                <div className={"formPart"}>
                    <h1>Retrouver votre mot de passe.</h1>
                    <p>Un oubli, ça arrive à tout le monde ! Merci de saisir votre adresse email afin que l'on vous renvoit un mot de passe.</p>
                    <div className={"containerForm"}>
                        <FormFindMyPassword />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindMyPassword
