import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./component/page/auth/Login"
import FindMyPassword from "./component/page/auth/FindMyPassword"
import FromProject from "./component/page/auth/FromProject"
import FromClient from "./component/page/auth/FromClient"
import "./css/Theme.css"

const Main = () => {
    const removeSession = () => {
        localStorage.clear()
        sessionStorage.clear()

        window.location.href = process.env.REACT_APP_URL
    }
    const redirectProcess = () => {
        if (localStorage.getItem("logged") !== null)
            removeSession()

        let pathname = window.location.pathname.replace("/", "")
        let emptyPath = [""]
        let loginPath = ["login"]

        // LOGIN PROCESS
        if (!loginPath.includes(pathname) && localStorage.getItem("token") === null) {
            window.location.href = "/login"
            return
        }

        // REFRESH OR UNLOG PROCESS
        const expiresIn = parseInt(localStorage.getItem("expires_in"))

        if (expiresIn < (Date.now() / 1000)) {
            removeSession()
        }

        // TARGET PROCESS IF NEEDED
        if (emptyPath.includes(pathname)) {
            localStorage.clear()
            const target = localStorage.getItem("target")

            switch (target) {
                case "project":
                    window.location.href = process.env.REACT_APP_PROJECT_URL
                    return
                case "client":
                default:
                    window.location.href = process.env.REACT_APP_CLIENT_URL
                    return
            }
        }
    }

    return (
        <BrowserRouter>
            {
                redirectProcess()
            }
            <Routes>
                <Route exact path="/fromclient" element={<FromClient />} />
                <Route exact path="/fromproject" element={<FromProject />} />
                <Route exact path="/forgot" element={<FindMyPassword />} />
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Main
