import React from 'react'
import Main from "./Main"
import './css/App.css'
import './css/App-device.css'

function App() {
    return (
        <Main />
    )
}

export default App
