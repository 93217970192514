import axios from 'axios'
import packageJson from "../../../package.json"

class Rest {
    _axios = axios
    _baseURL = process.env.REACT_APP_API_URL
    _tentative = false
    _contentType = 'application/json'
    _credentials = {
        agent: '',
        user: '',
        token: '',
        version: ''
    }
    _response = ''
    _error = false
    _debug = ['local', 'docker', 'dev', 'staging'].includes(process.env.REACT_APP_ENV)
    _completeUrl = true
    _external = false
    _method = ""
    _url = ""
    _data = {}
    _callback = null
    _callbackProgress = null

    init() {
        this._credentials.agent = packageJson.name
        this._credentials.user = "/"
        this._credentials.token = ""
        this._credentials.version = packageJson.version

        if (localStorage.getItem("token") === null) return
        this._credentials.token = localStorage.getItem("token")

        if (localStorage.getItem("user") === null) return
        let user = JSON.parse(localStorage.getItem("user"))

        if (Object.keys(user).length === 0) return
        this._credentials.user = (user.firstname + " " + user.lastname).trim()
    }

    sendQuery(method, url, data = {}, callback = null, callbackProgress = null, reponseType = null) {
        this._method = method
        this._url = url
        this._data = data
        this._callback = callback
        this._callbackProgress = callbackProgress

        this.init()

        let URL = ""
        let headers = {
            "Content-Type": this._contentType
        }

        if (!this._external) {
            headers["Version"] = this._credentials.version;
            headers["Authorization"] = this._credentials.token !== "" ? "Bearer " + this._credentials.token : "";
        }

        if (this._completeUrl)
            URL = this._baseURL + url
        else
            URL = url

        if(this._debug)
            console.log("REST QUERY", URL, method, headers, data)

        switch (method) {
            case "GET":
                this.get(URL, headers, reponseType)
                break
            case "POST":
                this.post(URL, headers, data)
                break
            case "PUT":
                this.put(URL, headers, data)
                break
            case "PATCH":
                this.patch(URL, headers, data)
                break
            case "DELETE":
                this.delete(URL, headers)
                break
            default:
                console.log("Method undefined...!")
                break
        }
    }

    get (URL, headers, responseType) {
        let options = {
            headers: headers
        }

        if (responseType !== null)
            options.responseType = responseType

        this._axios.get(
            URL,
            options
        ).then(response => {
            this.then(response);
        })
            .catch(error => {
                this.catch(error);
            })
    }
    post (URL, headers, data) {
        this._axios.post(
            URL,
            data,
            {
                headers: headers,
                onUploadProgress: function( progressEvent ) {
                    if (this._callbackProgress !== null)
                        this._callbackProgress(parseInt(Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )));
                }.bind(this)
            }
        ).then(response => {
            this.then(response);
        })
            .catch(error => {
                this.catch(error);
            })
    }
    put (URL, headers, data) {
        this._axios.put(
            URL,
            data,
            {
                headers: headers
            }
        ).then(response => {
            this.then(response);
        })
            .catch(error => {
                this.catch(error);
            })
    }
    patch (URL, headers, data) {
        this._axios.patch(
            URL,
            {
                headers: headers
            },
            data
        )
    }
    delete (URL, headers) {
        this._axios.delete(
            URL,
            {
                headers: headers
            }
        ).then(response => {
            this.then(response);
        })
            .catch(error => {
                this.catch(error);
            })
    }

    then (response) {
        this._response = response
        if(this._debug) console.log("RESPONSE", response)

        if(this._callback !== null)
            this._callback(this._response, this._error, response.status)
    }
    catch (error) {
        this._error = error

        if(this._debug && error.response !== undefined) {
            console.error("ERROR TEMP", error)

            if(error.response.status !== undefined)
                console.error("ERROR_STATUS", error.response.status)

            if(error.response.data !== undefined)
                console.error("ERROR_MESSAGE", error.response.data.message)
        }

        if (!this._external) {
            let callingCallback = true

            if(error.response !== undefined) {
                switch (error.response.status) {
                    case 401:

                        if (!this._url.includes("/login")) {
                            if (!this._tentative && localStorage.getItem("remember_me") !== null && localStorage.getItem("remember_me") === "true") {
                                this.refresh()
                                callingCallback = false
                            }
                            else {
                                this.removeSession()
                            }
                        }

                        break

                    default: break
                }
            }

            if (callingCallback && this._callback !== null)
                this._callback(this._response, this._error, error.response !== undefined ? error.response.status : 0)
        }
    }

    refresh() {
        this._contentType = 'application/json'

        let headers = {
            "Content-Type": this._contentType
        }

        if(!this._external) {
            headers["version"] = this._credentials.version

            if (this._credentials.agent !== "") headers["X-Agent"] = this._credentials.agent
            if (this._credentials.user !== "") headers["X-User"] = this._credentials.user
            if (this._credentials.token !== "") headers["Authorization"] = "Bearer " + this._credentials.token
        }

        this._tentative = true
        const url = this._baseURL + "/refresh"

        if(this._debug) {
            console.log("REFRESH")
            console.log("url", url)
            console.log("headers", headers)
        }

        this._axios({
            method: "POST",
            url: url,
            headers
        })
            .then((response) => {
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("expires_in", Math.floor((Date.now() + (response.data.expires_in * 1000)) / 1000).toString())

                this.sendQuery(this._method, this._url, this._data, this._callback, this._callbackProgress)
            })
            .catch(error => {
                this._error = error
                this.removeSession()
            })
    }

    removeSession() {
        if (['local', 'docker', 'dev', 'staging'].includes(process.env.REACT_APP_ENV)) {
            console.log("[CALLED] REMOVE SESSION")
        }
        else {
            localStorage.clear()
            sessionStorage.clear()
            window.location.reload()
        }
    }
}

export default Rest
