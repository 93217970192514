import React from "react"
import FormLogin from "../../form/login/FormLogin"

const Login = () => {
    return (
        <div className={"container"}>
            <div className={"box"}>
                <div className={"presentation"} />
                <div className={"formPart"}>
                    <h1>Content de vous revoir.</h1>
                    <p>Pour rester connecté avec nous, merci de saisir votre adresse email et votre mot de passe.</p>
                    <div className={"containerForm"}>
                        <FormLogin />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login