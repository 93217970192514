import React, {useEffect, useState} from "react"
import AuthController from "../../../stories/_auth/Auth/AuthController"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'
import Cookies from "universal-cookie";

const FormFindMyPassword = () => {
    const controller = new AuthController()

    const [ values, setValues ] = useState({
        email: ""
    })
    const [ errors, setErrors ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ readyToAuthenticate, setReadyToAuthenticate ] = useState(false)
    const [ globalValid, setGlobalValid ] = useState(null)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Email",
            attribute: "email",
            inputType: "text",
            returnType: "string",
            icon: "mail",
            idWrapper: "emailInput",
            classnameWrapper: "flex",
            classnameLabel: "",
            classnameInput: "",
            placeholder: "Email",
            emptyText: "Aucune"
        }
    ])

    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)
    }
    const call = () => {
        if (!readyToAuthenticate) return

        reinitAllEdits()
        retrieve()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const retrieve = () => {
        setLoading(true)

        controller._callback = handleRetrieve
        controller.findMyPassword(values.email)
    }
    const handleRetrieve = (response, error, status)  => {
        setLoading(false)

        switch (status) {
            case 200:
                setGlobalError(null)
                setGlobalValid("Votre nouveau mot de passe vient de vous être envoyé par email")
                break
            case 404:
                setGlobalError("Cette adresse email n'existe pas")
                break
            default:
                if(error.message === "Network Error")
                    setGlobalError("Le serveur est indisponible pour le moment")
                else
                    setGlobalError("Une erreur s'est produite lors de l'authentification")
                break
        }
    }
    const handleSubmit = event => {
        if (!readyToAuthenticate) return

        if (event !== undefined)
            event.preventDefault()

        call()
    }
    const controlInputs = () => {
        let emailRegex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm
        setReadyToAuthenticate(emailRegex.test(values.email))
    }

    useEffect(() => {
        document.title = "Retrouver mon mot de passe"
    }, [])
    useEffect(() => {
        controlInputs()
    }, [values])

    return (
        <form className="form">
            {
                globalError !== null
                && <p className="error">{globalError}</p>
            }
            {
                globalValid !== null
                && <p className="valid">{globalValid}</p>
            }
            <div className={"wrapper"}>
                {
                    rows.map((row, index) => (
                        <div key={index}
                             id={row.idWrapper}
                             className={"containerInput" + (row.classnameWrapper !== undefined && row.classnameWrapper.length > 0 ? " " + row.classnameWrapper : "")}>
                            {
                                (row.icon !== undefined && row.icon.length > 0)
                                && <div className={"icon"}>
                                    {
                                        FormBuilder.buildIcon(row.icon)
                                    }
                                </div>
                            }
                            <div className={"input"}>
                                <label
                                    className={"label" + (row.classnameLabel !== undefined && row.classnameLabel.length > 0 ? " " + row.classnameLabel : "")}>{row.label}</label>
                                {
                                    FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={"submit right" + (!readyToAuthenticate ? " disable" : "")} onClick={handleSubmit}>
                {
                    loading
                        ? <LoaderCircle classname="loader submitForm" strokeWidth="8" stroke="#FFFFFF"/>
                        : "Retrouver mon mot de passe"
                }
            </div>
        </form>
    );
}

export default FormFindMyPassword
