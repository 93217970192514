import React from "react";

function ToUppercase (props) {
    const { fill, classname, handleClick } = props;

    return (
        <svg width="1em" height="1em" viewBox="0 0 28.496 28.496" fill={ fill } className={ classname } onClick={ handleClick }>
            <path d="M11.44 15.353c0-2.99-1.352-5.486-5.59-5.486-2.314 0-4.056.624-4.94 1.118l.728 2.548c.832-.52 2.21-.962 3.51-.962 1.95 0 2.314.962 2.314 1.639v.183C2.964 14.365 0 15.952 0 19.253c0 2.028 1.534 3.9 4.108 3.9 1.508 0 2.808-.547 3.64-1.562h.078l.234 1.274h3.562c-.13-.702-.182-1.872-.182-3.096v-4.416zm-3.848 2.886c0 .232-.026.468-.078.676-.26.807-1.066 1.456-2.054 1.456-.884 0-1.56-.494-1.56-1.508 0-1.534 1.612-2.028 3.692-2.003v1.379zM23.062 5.343h-5.201l-5.355 17.524h4.108l1.247-4.498h5.019l1.353 4.498h4.264L23.062 5.343zm-4.628 10.061l1.041-3.718c.285-1.014.545-2.34.806-3.38h.052c.26 1.04.572 2.34.884 3.38l1.093 3.718h-3.876zM8.025 6.584H1.999v1.125h6.026V8.95l4.417-1.804-4.417-1.803z" />
        </svg>
    );
}

export default ToUppercase;
